import React from "react";
import { jsx, Box } from "theme-ui";
import { Flex, Heading, Text, Button } from "./core";
import { BackgroundVague } from "./svg";

/** @jsx jsx */

const Contact = ({ colorVague = "background3", ...props }) => {
  return (
    <Flex
      as="section"
      sx={{
        paddingTop: [15, 21, 31],
        paddingBottom: 9,
        backgroundColor: "primary",
        position: "relative",
        backgroundImage: "url(/img/bg-contact.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      {...props}
    >
      <BackgroundVague
        width="100%"
        height={null}
        color={colorVague}
        sx={{ position: "absolute", top: "-1px" }}
      />
      <Box sx={{ textAlign: "center", px: [2, 2, 0] }}>
        <Heading as="h2" variant="h1" color="white" sx={{ marginBottom: 3 }}>
          Nos projets vous plaisent ?
        </Heading>
        <Text variant="h4" color="white" fontWeight="regular">
          Nous proposons des partenariats avec les entreprises, les
          collectivités et les associations.
        </Text>
        <Button
          sx={{
            marginTop: 6,
            mx: "auto",
          }}
          to="/contact"
          backgroundColor="secondary"
        >
          Nous contacter
        </Button>
      </Box>
    </Flex>
  );
};

export default Contact;
