import React, { useState } from "react";
/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import { Flex, Heading, Text, FlexGrid, AspectRatio, Button } from "../core";
import { Location, Vague } from "../svg";
import { entranceScale, Image as AnimateImage } from "../animate";
import { useBreakpointIndex } from "../../hooks/breakpoints";

const Event = ({ children, image, direction, backgroundColor, to }) => {
  const [isHover, setIsHover] = useState(false);
  const breakpointIndex = useBreakpointIndex();

  return (
    <FlexGrid columns={[12, null, 6]} gutter={0} direction={direction}>
      <AnimateImage
        isAnimated={isHover}
        src={image}
        transition={{ duration: 1 }}
        variants={entranceScale({ delta: breakpointIndex > 1 ? 0.15 : 0 })}
        full
      />
      <Box>
        <AspectRatio
          ratio={[120 / 120, 120 / 95, 1.666]}
          px={[4, 8, 7, 12]}
          sx={{ backgroundColor }}
        >
          <Flex direction="column" sx={{ height: "100%", textAlign: "center" }}>
            {children}
            <Vague
              color="primary"
              sx={{
                marginTop: [6, null, 5, 6],
                marginBottom: [5, null, 4, 5],
              }}
            />
            <Button
              to={to}
              backgroundColor={
                backgroundColor === "white" ? "secondary" : "primary"
              }
              onHoverStart={() => setIsHover(true)}
              onHoverEnd={() => setIsHover(false)}
            >
              En savoir plus
            </Button>
          </Flex>
        </AspectRatio>
      </Box>
    </FlexGrid>
  );
};

const EventDate = ({ color = "primary", sx, ...props }) => (
  <Text
    variant="h6"
    color={color}
    uppercase
    sx={{ marginBottom: 2, ...sx }}
    {...props}
  />
);

const EventTitle = ({ color = "secondary", sx, ...props }) => (
  <Heading
    as="h3"
    variant={["h3", "h2", "h3", "h2"]}
    color={color}
    sx={{ marginBottom: 1, ...sx }}
    {...props}
  />
);

const EventPlace = ({
  children,
  colorSvg = "primary",
  colorText = "secondary",
  ...props
}) => (
  <Flex {...props}>
    <Location color={colorSvg} />
    <Text
      color={colorText}
      fontWeight="semiBold"
      sx={{ marginLeft: 1, opacity: 0.7 }}
    >
      {children}
    </Text>
  </Flex>
);

export { Event, EventDate, EventPlace, EventTitle };
